import { useQuery } from '@/hooks/useQuery'
import { useMemo } from 'react'
import { ErrorMessagesByStatus } from '@/constants'
import { Typography } from '@mui/material'

export function ErrorPage() {
  const query = useQuery()

  const error = useMemo(() => {
    const title = query.get('title')
    const description = query.get('description')

    if (title || description) {
      return { title, description }
    } else {
      return (
        ErrorMessagesByStatus[query.get('status') || 'unknown'] || ErrorMessagesByStatus.unknown
      )
    }
  }, [query])

  return (
    <div style={{ textAlign: 'center' }}>
      <Typography variant="h2" color="text.primary">
        {error.title}
      </Typography>
      <Typography variant="subtitle2" color="text.secondary">
        {error.description}
      </Typography>
    </div>
  )
}
