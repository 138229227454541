import * as yup from 'yup'
import { buildSchemaObject, withoutHiddenFields } from '@/helpers/formBuilder'
import { useFormik } from 'formik'
import {
  IAsyncRequestResult,
  EFormFieldType,
  IFormField,
  TPaymentService,
  IPaymentServiceConfiguration,
  Provider,
} from '@/models'
import { getFieldErrors, getErrorMessages } from '@/helpers/errors'
import { useToast } from '@/hooks/useToast'
import { BaseForm } from '@/components/forms/Form'
import { trimFormValues } from '@/helpers/trimFormValues'
import { AppConstants } from '@/constants'
import set from 'lodash/set'
import get from 'lodash/get'
import { forwardRef, useImperativeHandle } from 'react'

interface IConnectPaymentServiceFormProps {
  paymentService: TPaymentService
  fields: IFormField<TPaymentService>[]
  onCancel: () => void
  onSuccess: () => void
  onSubmit: (
    paymentService: TPaymentService,
    params: Partial<IPaymentServiceConfiguration>,
  ) => Promise<IAsyncRequestResult<TPaymentService>>
}

export const ConnectPaymentServiceForm = forwardRef(
  (props: IConnectPaymentServiceFormProps, ref) => {
    const { paymentService } = props

    const writableFields = props.fields.filter((field) => !field.readOnly)
    const formFields = getFormFields(paymentService)
    const topFormFields = formFields.find((field) => field.position === 'top')?.fields || []
    const bottomFormFields = formFields.find((field) => field.position === 'bottom')?.fields || []
    const fields = withoutHiddenFields([...topFormFields, ...writableFields, ...bottomFormFields])

    const toast = useToast()

    const validationSchema = yup.object(buildSchemaObject(fields))
    const formik = useFormik<Partial<IPaymentServiceConfiguration>>({
      initialValues: getInitialValues(paymentService, writableFields),
      validationSchema: validationSchema,
      onSubmit: (values) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { status, ...params } = values // Remove status from params
        return props
          .onSubmit(
            paymentService,
            trimFormValues({
              provider: paymentService.provider,
              ...params,
            }),
          )
          .then((result) => {
            if (result.error) {
              result.error.errors && formik.setErrors(getFieldErrors(result.error))

              const errorMessages = [
                paymentService.connected
                  ? 'Failed to update payment service'
                  : 'Failed to connect payment service',
                ...getErrorMessages(result.error),
              ]

              toast.showMessage(errorMessages.join('\n'), 'error')
            } else {
              toast.showMessage('Payment service updated', 'success')
              props.onSuccess()
            }
          })
      },
    })

    useImperativeHandle(ref, () => formik, [formik])

    return <BaseForm formikInstance={formik} onCancel={props.onCancel} formFields={fields} />
  },
)

const getInitialValues = (
  paymentService: TPaymentService,
  fields: IFormField<TPaymentService>[],
) => {
  const displayName =
    paymentService.provider === Provider.ADYEN &&
    paymentService?.extraContent?.systemType === 'ecom' &&
    !paymentService.status
      ? 'Pay Online with BLAZEPAY'
      : paymentService.displayName

  return {
    displayName,
    merchantName: paymentService.merchantName,
    copyCredentials: paymentService.copyCredentials,
    status: paymentService.status,
    ...fields.reduce((acc, field) => {
      return set(acc, field.name, get(paymentService, field.name) || field.initialValue || '')
    }, {}),
  }
}

const getFormFields = (paymentService: TPaymentService) => [
  {
    position: 'top',
    fields: [
      [
        {
          label: 'Merchant Name',
          placeholder: 'Insert merchant name',
          type: EFormFieldType.STRING,
          name: 'merchantName',
          hideOnConnect: !paymentService.requireApproval,
          disabled: paymentService.merchantName?.trim() !== '',
          schema: yup.string(),
        },
        {
          label: 'Display Name',
          placeholder: 'Insert display name',
          type: EFormFieldType.STRING,
          name: 'displayName',
          required: true,
          schema: yup
            .string()
            .required('Display Name is required')
            .max(
              AppConstants.PAYMENT_DISPLAY_NAME_LIMIT,
              `Display Name must have ${AppConstants.PAYMENT_DISPLAY_NAME_LIMIT} characters or less.`,
            ),
          dimensions: { xs: 6, md: 6, lg: 6 },
        },
      ],
    ],
  },
  {
    position: 'bottom',
    fields: [
      [
        {
          label: 'Use my KYC application for E-commerce and POS',
          description:
            'Enable to ensure all funds go to the same merchant account. If disabled, you will be required to submit a second application.',
          descriptionOnBottom: true,
          type: ({ formik }) => {
            if (
              (formik?.initialValues as TPaymentService).copyCredentials !== undefined ||
              !([Provider.ADYEN] as string[]).includes(paymentService.provider)
            ) {
              return EFormFieldType.HIDDEN
            }
            return EFormFieldType.BOOLEAN
          },
          name: 'copyCredentials',
          required: ([Provider.ADYEN] as string[]).includes(paymentService.provider),
          schema: yup.boolean(),
          dimensions: { xs: 12, md: 12, lg: 12 },
        },
      ],
    ],
  },
]
